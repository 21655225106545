import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { useMutation } from '@tanstack/react-query';
import type { ErgonomicWorkAnalysis } from '@/core/types/ErgonomicWorkAnalysis';
import { ErgonomicWorkAnalysisService } from '@/infra/services/ergonomic-work-analysis';

interface UpdateErgonomicWorkAnalysisParams {
	worker_stress_level_assessment_hidden?: boolean;
	performed_task_description_hidden?: boolean;
	planned_task_description_hidden?: boolean;
	working_verbalization_description?: string;
	working_conditions_description?: string;
	worker_stress_level_assessment?: string;
	working_verbalization_hidden?: boolean;
	location_description_hidden?: boolean;
	performed_task_description?: string;
	working_population_hidden?: boolean;
	working_conditions_hidden?: boolean;
	working_population_others?: number;
	planned_task_description?: string;
	working_population_women?: number;
	working_population_men?: number;
	conclusion_description?: string;
	location_description?: string;
	working_hour_minutes?: number;
	working_hour_hidden?: boolean;
	analysis_description?: string;
	demand_description?: string;
	method_description?: string;
	organization_id?: string;
	activity_ids?: string[];
	analysis_date?: string;
	working_hour?: number;
	evaluator_id?: string;
	company_id?: string;
	name?: string;
	step?: number;
	id?: string;
}

const Service = ErgonomicWorkAnalysisService.getInstance();

export const useUpdateErgonomicWorkAnalysis = () => {
	return useMutation<ErgonomicWorkAnalysis, AxiosError<Response>, UpdateErgonomicWorkAnalysisParams>({
		mutationFn: async (params) => Service.update(params),
		onSuccess: () => {
			message.success(I18n.get('Report updated successfully'));
		},
		onError: () => {
			message.error(I18n.get('Ops... something happened!'));
		}
	});
};
