import { BaseService } from './base-service';
import {
	GetErgonomicWorkAnalysisRequestDTO,
	GetErgonomicWorkAnalysisResponseDTO,
	CreateErgonomicWorkAnalysisRequestDTO,
	CreateErgonomicWorkAnalysisResponseDTO,
	UpdateErgonomicWorkAnalysisRequestDTO,
	UpdateErgonomicWorkAnalysisResponseDTO,
	GetErgonomicWorkAnalysisListResponseDTO,
	GetErgonomicWorkAnalysisListRequestDTO,
	DeleteErgonomicWorkAnalysisRequestDTO,
	DeleteErgonomicWorkAnalysisResponseDTO,
	GetErgonomicWorkAnalysisReportDownloadRequestDTO,
	GetErgonomicWorkAnalysisReportDownloadResponseDTO
} from '@/core/dto/ergonomic-work-analisys';

export class ErgonomicWorkAnalysisService extends BaseService {
	private static instance: ErgonomicWorkAnalysisService;

	private constructor(public readonly basePath: string = '/ergonomic-work-analysis') {
		super();
	}

	public static getInstance(): ErgonomicWorkAnalysisService {
		if (!ErgonomicWorkAnalysisService.instance) {
			ErgonomicWorkAnalysisService.instance = new ErgonomicWorkAnalysisService();
		}
		return ErgonomicWorkAnalysisService.instance;
	}

	public async create(
		params: CreateErgonomicWorkAnalysisRequestDTO
	): Promise<CreateErgonomicWorkAnalysisResponseDTO> {
		const { data } = await this.getInstance().post<CreateErgonomicWorkAnalysisResponseDTO>(this.basePath, params);
		return data;
	}

	public async get(params: GetErgonomicWorkAnalysisRequestDTO): Promise<GetErgonomicWorkAnalysisResponseDTO> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().get<GetErgonomicWorkAnalysisResponseDTO>(url, { params });
		return data;
	}

	public async update(
		params: UpdateErgonomicWorkAnalysisRequestDTO
	): Promise<UpdateErgonomicWorkAnalysisResponseDTO> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().put<UpdateErgonomicWorkAnalysisResponseDTO>(url, params);
		return data;
	}

	public async findAll(
		params: GetErgonomicWorkAnalysisListRequestDTO
	): Promise<GetErgonomicWorkAnalysisListResponseDTO> {
		const { data } = await this.getInstance().get<GetErgonomicWorkAnalysisListResponseDTO>(this.basePath, {
			params
		});
		return data;
	}

	public async downloadReport(
		params: GetErgonomicWorkAnalysisReportDownloadRequestDTO
	): Promise<GetErgonomicWorkAnalysisReportDownloadResponseDTO> {
		const url = this.basePath + `/download-report`;
		const { data } = await this.getInstance().get<GetErgonomicWorkAnalysisReportDownloadResponseDTO>(url, {
			params
		});
		return data;
	}

	public async delete(
		params: DeleteErgonomicWorkAnalysisRequestDTO
	): Promise<DeleteErgonomicWorkAnalysisResponseDTO> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().delete<DeleteErgonomicWorkAnalysisResponseDTO>(url, {
			params
		});
		return data;
	}
}
