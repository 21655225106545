import type { Method } from '@/types/Method';
import type { GetMethodResponseDTO } from '@/core/dto/method';

export class GetMethodMapper {
	constructor(private readonly data: GetMethodResponseDTO) {}

	toDomain(): Method {
		return {
			id: this.data.id,
			name: this.data.name,
			description: this.data.description,
			is_public: this.data.is_public
		};
	}
}
