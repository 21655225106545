import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { useMutation } from '@tanstack/react-query';
import { ErgonomicWorkAnalysis } from '@/core/types/ErgonomicWorkAnalysis';
import { ErgonomicWorkAnalysisService } from '@/infra/services/ergonomic-work-analysis';

export interface CreateErgonomicWorkAnalysisParams {
	organization_id: string;
	company_id: string;
	evaluator_id: string;
	name: string;
	analysis_date: string;
}

const Service = ErgonomicWorkAnalysisService.getInstance();

export const useCreateErgonomicWorkAnalysis = () => {
	return useMutation<ErgonomicWorkAnalysis, AxiosError<Response>, CreateErgonomicWorkAnalysisParams>({
		mutationFn: async (params) => Service.create(params),
		onSuccess: () => {
			message.success(I18n.get('Report created successfully'));
		},
		onError: () => {
			message.error(I18n.get('Ops... something happened!'));
		}
	});
};
