import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { QUERY_KEYS } from '@/utils/query-keys';
import { MethodService } from '@/infra/services/method';
import type { CreateMethodResponseDTO } from '@/core/dto/method';

interface CreateMethodParams {
	organization_id?: string;
	company_id?: string;
	name: string;
	description: string;
}

const Service = MethodService.getInstance();

export const useCreateMethod = () => {
	const queryClient = useQueryClient();
	return useMutation<CreateMethodResponseDTO, AxiosError<Response>, CreateMethodParams>({
		mutationFn: async (params) => Service.create(params),
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_METHOD_LIST_OPTIONS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_METHOD]);
			message.success(I18n.get('Method created successfully'));
		},
		onError: () => {
			message.error(I18n.get('Failed to create method'));
		}
	});
};
