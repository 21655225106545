import { useQuery } from '@tanstack/react-query';
import { ErgonomicWorkAnalysisService } from '@/infra/services/ergonomic-work-analysis';
import { QUERY_KEYS } from '@/utils/query-keys';

interface GetErgonomicWorkAnalysisParams {
	id: string;
	company_id: string;
	organization_id: string;
}

const Service = ErgonomicWorkAnalysisService.getInstance();

export const useGetErgonomicWorkAnalysis = (params: GetErgonomicWorkAnalysisParams) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_ERGONOMIC_WORK_ANALYSIS, params],
		queryFn: async () => Service.get(params),
		enabled: !!params.organization_id && !!params.company_id && !!params.id,
		retry: 0
	});
};
