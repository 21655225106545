import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from '@/utils/query-keys';
import { ErgonomicWorkAnalysisService } from '@/infra/services/ergonomic-work-analysis';
import type { AxiosErrorResponse } from '@/types';
import type {
	DeleteErgonomicWorkAnalysisRequestDTO,
	DeleteErgonomicWorkAnalysisResponseDTO
} from '@/core/dto/ergonomic-work-analisys';

const Service = ErgonomicWorkAnalysisService.getInstance();

export const useDeleteErgonomicWorkAnalysis = () => {
	const queryClient = useQueryClient();
	return useMutation<
		DeleteErgonomicWorkAnalysisResponseDTO,
		AxiosErrorResponse,
		DeleteErgonomicWorkAnalysisRequestDTO
	>({
		mutationFn: async (payload) => Service.delete(payload),
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ERGONOMIC_WORK_ANALYSIS_LIST]);
			message.success(I18n.get('Report deleted successfully'));
		},
		onError: (err) => {
			const errorMessage = err?.response?.data?.message;
			message.error(I18n.get(errorMessage));
		}
	});
};
