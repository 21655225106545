import { BaseService } from './base-service';
import type { Method } from '@/types/Method';
import { GetMethodListMapper, GetMethodMapper } from '@/core/mappers/method';
import type {
	CreateMethodRequestDTO,
	CreateMethodResponseDTO,
	DeleteMethodRequestDTO,
	DeleteMethodResponseDTO,
	GetMethodRequestDTO,
	GetMethodResponseDTO,
	GetMethodListRequestDTO,
	GetMethodListResponseDTO,
	UpdateMethodRequestDTO,
	UpdateMethodResponseDTO
} from '@/core/dto/method';

export class MethodService extends BaseService {
	private static instance: MethodService;

	private constructor(public readonly basePath: string = '/methods') {
		super();
	}

	public static getInstance(): MethodService {
		if (!MethodService.instance) {
			MethodService.instance = new MethodService();
		}
		return MethodService.instance;
	}

	public async index(params: GetMethodListRequestDTO): Promise<Method[]> {
		const url = this.basePath + '/';
		const { data } = await this.getInstance().get<GetMethodListResponseDTO>(url, {
			params
		});
		return new GetMethodListMapper(data).toDomain();
	}

	public async findOne(params: GetMethodRequestDTO): Promise<Method> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().get<GetMethodResponseDTO>(url, {
			params
		});
		return new GetMethodMapper(data).toDomain();
	}

	public async create(params: CreateMethodRequestDTO): Promise<CreateMethodResponseDTO> {
		const url = this.basePath + '/';
		const { data } = await this.getInstance().post<CreateMethodResponseDTO>(url, params);
		return data;
	}

	public async update(params: UpdateMethodRequestDTO): Promise<UpdateMethodResponseDTO> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().put<UpdateMethodResponseDTO>(url, params);
		return data;
	}

	public async delete(params: DeleteMethodRequestDTO): Promise<DeleteMethodResponseDTO> {
		const url = this.basePath + `/${params.id}`;
		const { data } = await this.getInstance().delete<DeleteMethodResponseDTO>(url, {
			params
		});
		return data;
	}
}
