import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { MethodService } from '@/infra/services/method';
import type { Method, MethodOption } from '@/types/Method';

interface GetMethodListOptionsParams {
	organization_id: string;
	company_id: string;
}

const Service = MethodService.getInstance();

const transformMethodOptions = (data: Method[]) => {
	return data.map((method) => ({
		value: method.id,
		label: method.name,
		is_public: method.is_public
	})) as MethodOption[];
};

export const useGetMethodListOptions = (params: GetMethodListOptionsParams) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_METHOD_LIST_OPTIONS, params],
		queryFn: async () => Service.index(params),
		enabled: !!params.organization_id && !!params.company_id,
		select: transformMethodOptions
	});
};
