import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { I18n } from '@aws-amplify/core';
import { QUERY_KEYS } from '@/utils/query-keys';
import { MethodService } from '@/infra/services/method';
import type { DeleteMethodResponseDTO } from '@/core/dto/method';

interface DeleteMethodParams {
	organization_id?: string;
	company_id?: string;
	id: string;
}

const Service = MethodService.getInstance();

export const useDeleteMethod = () => {
	const queryClient = useQueryClient();
	return useMutation<DeleteMethodResponseDTO, AxiosError<Response>, DeleteMethodParams>({
		mutationFn: async (params) => Service.delete(params),
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_METHOD_LIST_OPTIONS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_METHOD]);
			message.success(I18n.get('Method deleted successfully'));
		},
		onError: () => {
			message.error(I18n.get('Failed to delete method'));
		}
	});
};
