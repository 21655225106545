import { BaseService } from './base-service';
import { GetCustomReportsConsolidatedMapper } from '@/core/mappers/custom-reports';
import type { CustomReportConsolidatedList } from '@/types/CustomReport';
import type {
	GetCustomReportsDTO,
	GetCustomReportsEvolutionDTO,
	GetCustomReportsEvolutionParamsDTO,
	GetCustomReportsParamsDTO,
	GetCustomReportWorstScoresDTO,
	GetCustomReportWorstScoresParamsDTO,
	GetCustomReportCountHigherRiskFoundDTO,
	GetCustomReportRiskEvolutionParamsDTO,
	GetCustomReportRiskEvolutionDTO,
	GetCustomReportCountHigherRiskFoundParamsDTO,
	GetCustomReportsConsolidatedRequestDTO,
	GetCustomReportsConsolidatedResponseDTO,
	GetScoreScalesRequestDTO,
	GetScoreScalesResponseDTO,
	GetWorkstationsCountDTO,
	GetWorkstationsCountParamsDTO
} from '@/core/dto';

export class CustomReportService extends BaseService {
	private static instance: CustomReportService;

	private constructor(public readonly basePath: string = '/custom-report') {
		super();
	}

	public static getInstance(): CustomReportService {
		if (!CustomReportService.instance) {
			CustomReportService.instance = new CustomReportService();
		}
		return CustomReportService.instance;
	}

	public async findAll(params: GetCustomReportsParamsDTO): Promise<GetCustomReportsDTO[]> {
		const url = this.basePath + `/find-all/common`;
		const { data } = await this.getInstance().get<GetCustomReportsDTO[]>(url, { params });
		return data;
	}

	public async findOneByName(params: any): Promise<any> {
		const url = this.basePath + `/find-one/name`;
		const { data } = await this.getInstance().get<any>(url, { params });
		return data;
	}

	public async getWorstScores(params: GetCustomReportWorstScoresParamsDTO): Promise<GetCustomReportWorstScoresDTO> {
		const url = this.basePath + `/result/worst-score`;
		const { data } = await this.getInstance().get<GetCustomReportWorstScoresDTO>(url, { params });
		return data;
	}

	public async getReportsEvolution(
		params: GetCustomReportsEvolutionParamsDTO
	): Promise<GetCustomReportsEvolutionDTO> {
		const url = this.basePath + `/result/report/evolution`;
		const { data } = await this.getInstance().get<GetCustomReportsEvolutionDTO>(url, { params });
		return data;
	}

	public async getRiskEvolution(
		params: GetCustomReportRiskEvolutionParamsDTO
	): Promise<GetCustomReportRiskEvolutionDTO> {
		const url = this.basePath + `/result/risk/evolution`;
		const { data } = await this.getInstance().get<GetCustomReportRiskEvolutionDTO>(url, { params });
		return data;
	}

	public async getCountHigherRiskFound(
		params: GetCustomReportCountHigherRiskFoundParamsDTO
	): Promise<GetCustomReportCountHigherRiskFoundDTO> {
		const url = this.basePath + `/result/risk/count`;
		const { data } = await this.getInstance().get<GetCustomReportCountHigherRiskFoundDTO>(url, { params });
		return data;
	}

	public async getWorkstationsCount(params: GetWorkstationsCountParamsDTO): Promise<GetWorkstationsCountDTO> {
		const url = this.basePath + `/result/workstations/count`;
		const { data } = await this.getInstance().get<GetWorkstationsCountDTO>(url, { params });
		return data;
	}

	public async getScoreScale(params: GetScoreScalesRequestDTO): Promise<GetScoreScalesResponseDTO[]> {
		const url = this.basePath + `/score-scale/find-all`;
		const { data } = await this.getInstance().get<GetScoreScalesResponseDTO[]>(url, { params });
		return data;
	}

	public async getCustomReportsConsolidated(
		params: GetCustomReportsConsolidatedRequestDTO
	): Promise<CustomReportConsolidatedList> {
		const url = this.basePath + `/result/reports`;
		const { data } = await this.getInstance().get<GetCustomReportsConsolidatedResponseDTO>(url, { params });
		return new GetCustomReportsConsolidatedMapper(data).toDomain();
	}

	public async listAllFilesConsolidated(params: any): Promise<any> {
		const url = this.basePath + `/result/files/consolidated`;
		const { data } = await this.getInstance().get<any>(url, { params });
		return data;
	}
}
