import type { Method } from '@/types/Method';
import type { GetMethodListResponseDTO } from '@/core/dto/method';

export class GetMethodListMapper {
	constructor(private readonly data: GetMethodListResponseDTO) {}

	toDomain(): Method[] {
		return this.data.rows.map((value) => ({
			id: value.id,
			name: value.name,
			description: value.description,
			is_public: !!value.is_public
		}));
	}
}
