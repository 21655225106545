import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import type { CustomReportConsolidatedList } from '@/types/CustomReport';
import type { GetCustomReportsConsolidatedResponseDTO } from '@/core/dto/custom-report/get-custom-reports-consolidated';

export class GetCustomReportsConsolidatedMapper {
	constructor(private readonly data: GetCustomReportsConsolidatedResponseDTO) {}

	toDomain(): CustomReportConsolidatedList {
		return {
			limit: this.data.limit,
			offset: this.data.offset,
			total: this.data.total,
			rows: this.data.rows.map((row) => ({
				id: row.id,
				creation_date: moment(row.creation_date).format('L'),
				activity_name: I18n.get(row.activity),
				report_type: I18n.get(row.type_of_report),
				risk_matrix: I18n.get(row.risk_matrix)
			}))
		};
	}
}
