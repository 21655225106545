import { useQuery } from '@tanstack/react-query';
import { MethodService } from '@/infra/services/method';
import { QUERY_KEYS } from '@/utils/query-keys';

interface GetMethodParams {
	organization_id: string;
	company_id: string;
	id: string;
}

const Service = MethodService.getInstance();

export const useGetMethod = (params: GetMethodParams) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_METHOD, params],
		queryFn: async () => Service.findOne(params),
		enabled: !!params.organization_id && !!params.company_id && !!params.id
	});
};
